<script lang="ts" setup>
import type { Button } from "@/types";

const props = defineProps<{ blok: Button }>();

const shouldShowIcon = computed(() => {
  return (
    !props.blok.design?.includes("rounded") &&
    !props.blok.design?.includes("plain")
  );
});

const designClass = computed(() => {
  switch (props.blok.design) {
    case "default":
      return "flex text-base w-full lg:font-helvetica-light font-helvetica-bold lg:text-3xl py-5 border-b-[0.5px] border-b-black justify-between";
    case "rounded":
      return "inline-flex py-4 lg:py-[19px] px-[49px] mt-6 text-white bg-black rounded-full";
    case "rounded-white":
      return "inline-flex py-4 lg:py-[19px] px-[49px] mt-6 text-black bg-white rounded-full";
    case "text":
      return "flex text-base font-helvetica-bold py-5 gap-6";
    case "plain":
      return "flex text-base font-helvetica-bold py-5 gap-6";
  }
});
</script>

<template>
  <BlokLink
    v-editable="blok"
    :blok="blok.url"
    :is-form="blok.isForm"
    :class="designClass"
  >
    {{ blok.text }}
    <NuxtImg
      v-if="shouldShowIcon"
      width="24px"
      height="24px"
      src="/icon/chevron-right-rounded.svg"
      alt="Chevron right"
    />
  </BlokLink>
</template>
